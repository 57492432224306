import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "デザイン言語システムを入れたらコミュニケーションコストがぐっと下がった話〜メドレー TechLunch〜",
  "date": "2017-08-03T07:00:00.000Z",
  "slug": "entry/2017/08/03/160000",
  "tags": ["medley"],
  "hero": "./2017_08_03.png",
  "heroAlt": "dsl"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`ビールが美味しい季節ですね！`}</p>
    <p>{`最近飲みすぎて嫁に叱られて、飲み会自粛中のデザイナー・`}<a parentName="p" {...{
        "href": "https://www.wantedly.com/companies/medley/post_articles/49119"
      }}>{`マエダ`}</a>{`です。`}</p>
    <p>{`メドレーでは TechLunch という社内勉強会を実施しているのですが、デザインについて私も発表する機会をいただきましたので、その内容を紹介させていただきます。テーマは「DLS の導入について」です。発表資料は記事の最後をご覧ください。`}</p>
    <h1>{`DLS（デザイン言語システム）とは`}</h1>
    <p>{`DLS とは DesignLanguageSystem の略で、すごい単純にいえばデザインガイドラインみたいに`}<strong parentName="p">{`UI に一貫性をもたせるため、配色やレイアウト、タイポグラフィやマージンなどのルールを策定するもの`}</strong>{`です。`}</p>
    <p>{`私が主に担当しているオンライン診療アプリ「`}<a parentName="p" {...{
        "href": "https://clinics.medley.life/"
      }}>{`CLINICS`}</a>{`」は、iOS、Android、Web と 3 つのプラットフォームで運用しているのですが、入社した当初はプラットフォーム毎に違った UI やルールで開発しており、サービスとして一貫性のあるサービス体験を提供できるとは言えない状況でした。また新たに機能を追加する際、それぞれ違なるデザインをしなければならず、デザイン作業においても負荷がかかっていました。`}</p>
    <h1>{`DLS が必要な理由`}</h1>
    <p>{`CLINICS ではプラットフォームごとに異なる UI を提供していたため、一貫したサービス品質をユーザーに提供できていないこと、開発者ごとに UI に対して認識にズレが生じていたことが課題でした。それに伴い開発速度も決して速いとは言えず、どうにか一定の品質を担保しつつ開発スピードも改善できないかと悩んでいたところ、Airbnb の開発者ブログで Airbnb Design System という記事を見かけました。`}</p>
    <p>{`これまでのデザインガイドラインは単にカラーやマージンの定義を取り決めるだけだったのですが、`}<strong parentName="p">{`Airbnb ではデザイン言語として定義し、他の言語と同じようにチームと共有し、エンジニア・デザイナー同士で理解できる設計を作り上げている`}</strong>{`といった内容でした。`}</p>
    <p>{`※参考`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="Building a Visual Language – Airbnb Design – Medium" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fmedium.com%2Fairbnb-design%2Fbuilding-a-visual-language-behind-the-scenes-of-our-airbnb-design-system-224748775e4e" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://medium.com/airbnb-design/building-a-visual-language-behind-the-scenes-of-our-airbnb-design-system-224748775e4e">medium.com</a></cite>
    <p>{`私がデザイナーとしてサービスデザインに携わる重要な役割のひとつとして、単にデザインをするだけでなく`}<strong parentName="p">{`デザインを通して UI 設計の制約をつくり、継続的に運用しやすいプロダクトに仕上げること`}</strong>{`があると思っています。`}</p>
    <p>{`DLS を起点として、各プラットフォームの開発者が共通の認識でシステム開発が行えれば、これまで以上にスピーディが開発が行え、一貫性のある体験をユーザーに提供できるプロダクトにできるはずだと考え、CLINICS 独自の DLS を開発することにしました。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/d/dev-medley/20170725/20170725121754.png",
        "alt": "f:id:dev-medley:20170725121754p:plain",
        "title": "f:id:dev-medley:20170725121754p:plain"
      }}></img>{`
CLINICS の DLS の一部`}</p>
    <h1>{`DLS を導入してどうなったか`}</h1>
    <p>{`CLINICS では、Android、iOS、web で担当するエンジニアが異なるのですが、DLS を設計しシステムの詳細を共有することで、UI に対しての共通認識が生まれ、一貫した品質を担保できるようになりました。さらにこれまでエンジニアだけでデザインを考えて悩んでいた時間を、コンポーネント設計で組み立てたデザインを DLS で定義したことで、UI に悩むことなく機能ロジックに重点を置いた開発に専念できるようになったのではないかと思います。`}</p>
    <p>{`デザイン言語でサービス設計の基礎を築けたことで、開発だけに追われていた状況から、より良いサービスを作るためにはどんな UX をユーザーに提供すべきかという声がエンジニアからも頻繁に声が上がりはじめたことも良かった点です。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/d/dev-medley/20170725/20170725122157.jpg",
        "alt": "f:id:dev-medley:20170725122157j:plain",
        "title": "f:id:dev-medley:20170725122157j:plain"
      }}></img>{`
エンジニア陣が UI について熱い議論を交わしている様子`}</p>
    <p>{`TechLunch では、こうした内容について実際に作ったコンポーネント設計なども見せながらお話しました。発表資料はこちら。`}</p>
    <iframe id="talk_frame_401874" style={{
      "border": "0",
      "padding": "0",
      "margin": "0",
      "background": "transparent"
    }} src="https://speakerdeck.com/player/fef793505d9e4dcb8163cf1f13233a90" width="710" height="596" frameBorder="0" allowFullScreen="true"></iframe>
    <cite className="hatena-citation"><a href="https://speakerdeck.com/medley/clinics-dls-fan-shi-che-di-tesainyan-yu-sisutemufalsekoshao-jie">speakerdeck.com</a></cite>
    <h1>{`まとめ`}</h1>
    <p>{`DLS 導入以前は、エンジニアが開発に追われていたということもあり、プラットフォーム毎に議論ができていなかったエンジニアがお互いの担当プラットフォームを意識したコミュニケーションがとれるようになったことは予想外に良かった点です（別に仲が悪かったとかそういうことではなく w）。`}</p>
    <p>{`さらに DLS で UI の基盤をつくったことで、デザイナーが手を動かさずともコンポーネントの組み合わせを話し合うだけで、エンジニア完結で一貫した品質で機能実装できるようになりました。これにより私も次の施策やプロジェクトに専念できるようになり、効率的に仕事ができるようになりました。`}</p>
    <p>{`まだデザインルールに一貫性がないプロジェクトを担当しているデザイナーやエンジニアは、ぜひ DLS の導入を検討してみてはいかがでしょうか。
単にデザイン品質だけでなく、チームコミュニケーションも改善されるとおもいます。`}</p>
    <p>{`より詳しく話を聞きたいかたは、気軽に「`}<a parentName="p" {...{
        "href": "https://www.wantedly.com/companies/medley"
      }}>{`話を聞きに行きたい`}</a>{`」をクリックしてみてください。ビールを飲みながらデザイン談義をしましょう！（嫁に怒られない程度に w）`}</p>
    <p>{`メドレー開発本部について、もっと詳しく知りたい方はこちらからどうぞ。`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="デザイナー＆エンジニア採用 | 株式会社メドレー" src="https://hatenablog-parts.com/embed?url=http%3A%2F%2Fwww.medley.jp%2Frecruit%2Fcreative.html" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.medley.jp/recruit/creative.html">www.medley.jp</a></cite>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="株式会社メドレーの最新情報 - Wantedly" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fwww.wantedly.com%2Fcompanies%2Fmedley" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.wantedly.com/companies/medley">www.wantedly.com</a></cite>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      